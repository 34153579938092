import {useTranslation} from 'react-i18next';
import {getWeek, isToday, isTomorrow, startOfDay} from 'date-fns';
import {useMemo} from 'react';
import {useMapDataContext} from '../../../context/MapDataContext';
import {CapacityResponse} from '../../../services/api/types/types';
import {format} from '../../../lib/dates/format';

import './ClinicCapacity.scss';
import {VisitCategory} from './PickVisitCategory';

interface ClinicCapacityProps {
    visitReasons: Array<string>;
    capacity: CapacityResponse;
}

export function ClinicCapacity({capacity, visitReasons}: ClinicCapacityProps) {
    const {t} = useTranslation();

    const {first = null, within30Days, within7Days} = capacity;

    const nextSlotLabel = useMemo(() => {
        if (!first) {
            return '';
        }
        const date = new Date(first);
        const now = startOfDay(new Date());

        const weekDiff = getWeek(date) - getWeek(now);
        if (isToday(date)) {
            return t('available_times.today');
        }
        if (isTomorrow(date)) {
            return t('available_times.tomorrow');
        }
        if (weekDiff < 2) {
            return t('available_times.within_a_week');
        }
        if (weekDiff < 3) {
            return t('available_times.within_two_weeks');
        }

        return format(date, 'iiii, do MMM').replace('.', '').replace(':', '');
    }, [first, t]);

    const formatNumberOfTimeSlots = (numberOfTimeSlots: number) => {
        if (numberOfTimeSlots > 200 && numberOfTimeSlots < 499) {
            return '200+';
        } else if (numberOfTimeSlots >= 500 && numberOfTimeSlots < 999) {
            return '500+';
        } else if (numberOfTimeSlots >= 1000) {
            return '1000+';
        } else {
            return numberOfTimeSlots;
        }
    };

    const within7DaysLabel = useMemo(() => {
        return formatNumberOfTimeSlots(within7Days);
    }, [within7Days]);

    const within30DaysLabel = useMemo(() => {
        return formatNumberOfTimeSlots(within30Days);
    }, [within30Days]);

    const {state: mapDataState} = useMapDataContext();

    const reason = mapDataState?.visitReasons.find(({id}) => id === visitReasons[0]);
    const reasonLabel =
        visitReasons.length > 1
            ? t('capacity.slots_based_on_filters')
            : t('capacity.slots_for_reason', {
                  visitCategory: t(`reason_group.${reason?.category as VisitCategory}`),
                  reasonLabel: reason?.label
              });

    if (within30Days === 0 && within7Days === 0 && first === null) {
        const noSlotsLabel =
            visitReasons.length > 1
                ? t('capacity.no_slot_available_for_filters')
                : t('capacity.no_slot_available_for_reason', {
                      visitCategory: t(`reason_group.${reason?.category as VisitCategory}`),
                      reasonLabel: reason?.label
                  });

        return (
            <div className='clinic-capacity'>
                <p className='clinic-capacity__row'>
                    <b className='clinic-capacity__row__value'>{noSlotsLabel}</b>
                </p>
            </div>
        );
    } else {
        return (
            <div className='clinic-capacity'>
                <p className='clinic-capacity__row'>
                    <b className='clinic-capacity__row__value'>{reasonLabel}</b>
                </p>
                {within7Days === 0 && within30Days === 0 && (
                    <p className='clinic-capacity__row'>
                        <span className='clinic-capacity__next-slot'>
                            {t('capacity.slot_available_after_30_days', {availableSlot: nextSlotLabel})}
                        </span>
                    </p>
                )}
                {(within7Days > 0 || within30Days > 0) && (
                    <>
                        <p className='screen-reader-only'>
                            {`
                        ${t('capacity.aria.next_free_slot')}: ${nextSlotLabel},
                        ${t('capacity.within_7_days')}: ${t('capacity.aria.amount_of_slots', {
                                slotCount: within7DaysLabel
                            })},
                        ${t('capacity.within_30_days')} ${t('capacity.aria.amount_of_slots', {
                                slotCount: within30DaysLabel
                            })}
                        `}
                        </p>
                        <p className='clinic-capacity__row' aria-hidden>
                            {t('capacity.next_free_slot')}
                            <b className='clinic-capacity__row__value clinic-capacity__next-slot'>{nextSlotLabel}</b>
                        </p>
                        <p className='clinic-capacity__row' aria-hidden>
                            {t('capacity.within_7_days')}
                            <b className='clinic-capacity__row__value'>
                                {t('capacity.amount_of_slots', {slotCount: within7DaysLabel})}
                            </b>
                        </p>
                        <p className='clinic-capacity__row' aria-hidden>
                            {t('capacity.within_30_days')}
                            <b className='clinic-capacity__row__value'>
                                {t('capacity.amount_of_slots', {slotCount: within30DaysLabel})}
                            </b>
                        </p>
                    </>
                )}
            </div>
        );
    }
}
