import {ClinicForm} from '../../components/ClinicForm/ClinicForm';
import {useTranslation} from 'react-i18next';
import {useClinicDetailsContext} from '../../../../context/ClinicDetailsContext';
import {BookingActions, useBookingContext} from '../../../../context/BookingContext';

import './BookingInitial.scss';
import {useEffect, useState} from 'react';
import {fetchUnreserveTimeSlot} from '../../../../services/api/api';

export type BookingType = 'booking' | 'dropIn' | 'healthDeclaration';

export function BookingInitial() {
    const {t} = useTranslation();

    const {dataState} = useClinicDetailsContext();

    const {clinic, loading} = dataState;

    const {dispatch, bookingState} = useBookingContext();

    const [selectionActive, setSelectionActive] = useState(false);

    const bookingType = bookingState.bookingType;
    const hasVisitOption = clinic?.hasTimebook || clinic?.hasDropin;

    useEffect(() => window.scrollTo(0, 0), []);

    useEffect(() => {
        if (bookingState.bookingId === null || clinic === null) {
            return;
        }

        const clearTimeSlot = async () => {
            try {
                await fetchUnreserveTimeSlot(clinic.id, bookingState.bookingId as string);
                dispatch({type: BookingActions.CLEAR_BOOKING_ID});
            } catch (error) {
                console.log(error);
            }
        };

        clearTimeSlot();
    }, [bookingState.bookingId, clinic, dispatch]);

    useEffect(() => {
        if (!clinic) {
            return;
        }

        if (!clinic?.hasTimebook && clinic?.hasDropin) {
            dispatch({type: BookingActions.SET_BOOKING_TYPE, value: 'dropIn'});
        }
    }, [clinic, dispatch]);

    return (
        <main className='main-content main-content--initial' id='main'>
            <section className='main-content__section'>
                {loading && <span className='skeleton-box'></span>}
                {!loading && <h1 className='main-content__header main-content--initial__header'>{clinic?.name}</h1>}
            </section>
            {!hasVisitOption && (
                <section>
                    <p className='main-content__welcome wrap-html'>{clinic?.welcomeText}</p>
                </section>
            )}

            {hasVisitOption && (
                <div>
                    <nav
                        className={`nav-container ${selectionActive === false ? 'nav-container--no-selection' : ''}`}
                        role='tablist'
                    >
                        {clinic?.hasTimebook && (
                            <button
                                onClick={() => {
                                    dispatch({type: BookingActions.SET_BOOKING_TYPE, value: 'booking'});
                                    setSelectionActive(true);
                                }}
                                type='button'
                                className={`tab-option ${
                                    selectionActive && bookingType === 'booking' ? 'selected' : ''
                                } ${clinic?.hasDropin ? 'booking' : ''}`}
                            >
                                <span>{t('clinic_main.booking')}</span>
                            </button>
                        )}
                        {clinic?.hasDropin && (
                            <button
                                onClick={() => {
                                    dispatch({type: BookingActions.SET_BOOKING_TYPE, value: 'dropIn'});
                                    setSelectionActive(true);
                                }}
                                type='button'
                                className={`tab-option ${
                                    selectionActive && bookingType === 'dropIn' ? 'selected' : ''
                                } ${clinic?.hasTimebook ? 'drop-in' : ''}`}
                            >
                                <span>{t('clinic_main.drop_in')}</span>
                            </button>
                        )}
                    </nav>
                    <ClinicForm />
                </div>
            )}
        </main>
    );
}
