import {Popup} from 'react-leaflet';
import {useRef, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {fetchCapacity} from 'src/services/api/api';
import {CapacityResponse, CaregiverObject, ClinicObjectSlim} from 'src/services/api/types/types';
import {useMapDataContext} from 'src/context/MapDataContext';
import {ClinicCapacity} from 'src/components/main/components/ClinicCapacity';
import {XIcon} from 'src/components/icons/XIcon';
import './ClinicPopup.scss';

interface ClinicPopupProps {
    clinic: ClinicObjectSlim;
    isOpenId: string | null;
    reasonQuery: string;
    distanceToClinic?: {value: number; unit: string};
}

export function ClinicPopup({clinic, isOpenId, reasonQuery, distanceToClinic}: ClinicPopupProps) {
    const clinicDetailsUrl = process.env.REACT_APP_CLINIC_DETAILS_URL;
    const {t} = useTranslation();

    const {state: mapDataState} = useMapDataContext();

    const [capacity, setCapacity] = useState<null | CapacityResponse>(null);
    const popup = useRef<L.Popup>(null);

    const caregiverName =
        isOpenId === clinic.id
            ? mapDataState.careGivers.find((caregiver: CaregiverObject) => caregiver.id === clinic.principalCareGiverId)
                  ?.label
            : '';

    const close = () => {
        if (popup.current) {
            popup.current.close();
        }
    };
    const getPossibleBookingOptions = () => {
        if (clinic.hasDropin && clinic.hasTimebook) {
            return t('clinic_popup.booking_options.both');
        }
        if (clinic.hasDropin) {
            return t('clinic_popup.booking_options.dropin');
        }
        if (clinic.hasTimebook) {
            return t('clinic_popup.booking_options.timebook');
        }
    };

    useEffect(() => {
        const escFunction = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                close();
            }
        };

        if (isOpenId === clinic.id) {
            document.addEventListener('keydown', escFunction, false);
        }

        return () => document.removeEventListener('keydown', escFunction, false);
    }, [clinic.id, isOpenId]);

    useEffect(() => {
        if (popup.current) {
            popup.current.getElement()?.querySelector('button')?.focus();
        }

        if (!reasonQuery) {
            return;
        }

        if (isOpenId === clinic.id) {
            const abortSignal = new AbortController();

            const loadCapacity = async () => {
                const result = await fetchCapacity(
                    parseInt(clinic.id, 10),
                    abortSignal.signal,
                    new URLSearchParams(reasonQuery).getAll('reason')
                );
                setCapacity(result);
            };

            loadCapacity();
        }
    }, [clinic.id, isOpenId, reasonQuery]);

    return (
        <Popup
            autoPanPadding={[15, 15]}
            ref={popup}
            maxWidth={343}
            minWidth={343}
            closeButton={false}
            className='pop-up'
            closeOnClick={false}
        >
            <div tabIndex={-1} className='popup-header'>
                <button
                    className='close-button'
                    aria-label={`${t('button_close.text')} ${clinic.name}`}
                    onClick={() => close()}
                >
                    <XIcon />
                </button>
                <span>
                    {clinic?.logoUrl && <img src={clinic.logoUrl} alt={caregiverName} />}
                    {!clinic?.logoUrl && <>{caregiverName}</>}
                </span>
            </div>
            <div className='popup-main'>
                <h3 className='popup-main__header'>{clinic.name}</h3>
                <div
                    className={`popup-main__text-container${
                        clinic.address && !distanceToClinic ? ' popup-main__text-container--flex-column' : ''
                    }`}
                >
                    <p className='popup-main__text-container__text'>
                        {distanceToClinic && (
                            <>
                                <span className='screen-reader-only'>
                                    {t('distance_to_clinic.arialabel', distanceToClinic)}
                                </span>
                                <span aria-hidden>{`${distanceToClinic.value} ${distanceToClinic.unit} •`}</span>
                            </>
                        )}
                        <span aria-hidden>{distanceToClinic && <>&nbsp;</>}</span>
                        {clinic.address && `${clinic.address}, ${clinic.city}`}
                    </p>
                    <p className='popup-main__text-container__text'>{getPossibleBookingOptions()}</p>
                </div>
            </div>
            {clinic.hasTimebook && capacity !== null && reasonQuery !== '' && (
                <div className='popup-extra'>
                    <ClinicCapacity
                        capacity={capacity}
                        visitReasons={new URLSearchParams(reasonQuery).getAll('reason')}
                    />
                </div>
            )}
            <div className='popup-button'>
                <a
                    href={`${clinicDetailsUrl}/${clinic.id}${reasonQuery}`}
                    className='btn btn--primary'
                    target='_blank'
                    rel='noreferrer'
                >
                    {t('clinic_popup.button.text')}
                </a>
            </div>
        </Popup>
    );
}
