import {useTranslation} from 'react-i18next';
import {BookingActions, useBookingContext, User} from '../../../../context/BookingContext';
import {Input} from '../../../form/input/Input';
import './BookingConfirm.scss';
import {Checkbox} from '../../../form/checkbox/Checkbox';
import {useEffect, useState} from 'react';
import {Button} from '../../../form/button/Button';
import ModalPortal from '../../../layout/modal/ModalPortal';
import {useClinicDetailsContext} from '../../../../context/ClinicDetailsContext';
import {fetchReserveTimeSlot, postFinalizeBooking} from '../../../../services/api/api';
import {BookingInformation} from '../../components/BookingInformation/BookingInformation';
import {
    ApiErrorResponse,
    BookingPatient,
    ErrorCode,
    FinalizeBookingRequest
} from '../../../../services/api/types/types';
import {format} from '../../../../lib/dates/format';
import {BookingSessionActions, useBookingSessionContext} from '../../../../context/BookingSessionContext';
import {useSessionContext} from '../../../../context/SessionContext';
import {validateEmail, validatePhone} from '../../../../services/validation/validation';

export function BookingConfirm() {
    const {t} = useTranslation();
    const {dispatch, bookingState} = useBookingContext();
    const {dataState} = useClinicDetailsContext();
    const {bookingSessionDispatch} = useBookingSessionContext();
    const {sessionState} = useSessionContext();
    const [email, setEmail] = useState(bookingState.user?.email || '');
    const [phone, setPhone] = useState(bookingState.user?.mobile || '');
    const [sms, setSms] = useState(false);
    const [personal, setPersonal] = useState(false);
    const [marketing, setMarketing] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [errorCode, setErrorCode] = useState<ErrorCode>('ILLEGAL_DBL');
    const [isPhoneValid, setIsPhoneValid] = useState(validatePhone(phone));
    const [isEmailValid, setIsEmailValid] = useState(validateEmail(email));
    const [showError, setShowError] = useState<Error | null>(null);
    const [phoneHasBeenValid, setPhoneHasBeenValid] = useState(false);
    const [emailHasBeenValid, setEmailHasBeenValid] = useState(false);
    const [busy, setBusy] = useState(false);

    const {clinic} = dataState;

    const {selectedPersonsIds, healthDeclarationAnswers, user, tripDepartureDate, trip, bookingId, consentRequests} =
        bookingState;

    const {userJwt} = sessionState;

    useEffect(() => window.scrollTo(0, 0), []);

    useEffect(() => {
        if (phoneHasBeenValid) {
            if (!validatePhone(phone)) {
                setIsPhoneValid(false);
                setPhoneHasBeenValid(false);
            }
        }
        if (validatePhone(phone)) {
            setIsPhoneValid(true);
            if (phone.length > 0) setPhoneHasBeenValid(true);
        }
    }, [phone, phoneHasBeenValid]);

    useEffect(() => {
        if (emailHasBeenValid) {
            if (!validateEmail(email)) {
                setIsEmailValid(false);
                setEmailHasBeenValid(false);
            }
        }
        if (validateEmail(email)) {
            setIsEmailValid(true);
            if (email.length > 0) setEmailHasBeenValid(true);
        }
    }, [email, emailHasBeenValid]);

    const getHealthDecl = (id: string) => {
        return Object.keys(healthDeclarationAnswers.answers[id]).map((key) => {
            return {
                questionId: parseInt(key, 10),
                answer:
                    healthDeclarationAnswers.answers[id][parseInt(key, 10)] === true
                        ? ('yes' as const)
                        : ('no' as const)
            };
        });
    };

    const mapPatient = (patient: User) => {
        const payload: BookingPatient = {
            identity: patient.identity,
            givenName: patient.givenName,
            familyName: patient.familyName
        };
        if (bookingState.reasonState.appointmentType?.healthDeclarationRequired) {
            payload.healthDecl = {
                definitionId: bookingState.reasonState.healthDeclarationDefinitionId as number,
                answers: getHealthDecl(patient.identity)
            };

            if (tripDepartureDate) {
                payload.healthDecl.trip = {
                    date: format(tripDepartureDate, 'yyyy-MM-dd'),
                    destinations: trip as Array<{
                        countryId: number;
                        duration: number;
                    }>
                };
            }
        }

        return payload;
    };

    const patients = selectedPersonsIds.map((id) => {
        if (user?.identity === id) {
            return mapPatient(user);
        }

        return mapPatient(user?.relations?.find((relation) => relation.identity === id) as User);
    });
    const payloadConsentRequests = consentRequests
        ?.map(({id, displayName, ...request}) => request)
        .filter((request) => request.digital);

    const handleSubmit = async (bookingId: string) => {
        if (busy) {
            return;
        }

        setBusy(true);
        dispatch({type: BookingActions.SET_CONTACT_DETAILS, value: {email: email, mobile: phone}});

        const bookingData: FinalizeBookingRequest = {
            contactInfo: {
                marketing: marketing,
                preferredContact: email.length > 0 ? 'both' : 'sms',
                mobile: phone,
                ...(email.length > 0 && {email})
            },
            patients,
            consentRequests: payloadConsentRequests
        };

        try {
            const response = await postFinalizeBooking(clinic?.id as number, bookingId as string, userJwt, bookingData);

            if (response) {
                bookingSessionDispatch({type: BookingSessionActions.CLEAR_USER_SESSION});
                dispatch({type: BookingActions.SET_BOOKING_CODE, value: response.bookingCode});
                dispatch({type: BookingActions.SET_BOOKING_STEP, value: 'completed'});
                dispatch({type: BookingActions.RESET_BOOKING_ID});
            }
        } catch (error) {
            if ((error as ApiErrorResponse).errorCode === 'NO_SUCH_RES') {
                try {
                    const {bookingId: newBookingId} = await fetchReserveTimeSlot(
                        clinic?.id as number,
                        bookingState.reasonState.appointmentType?.id as number,
                        bookingState.timeSlotState.selectedSlot as Date
                    );
                    if (newBookingId) {
                        await handleSubmit(newBookingId);
                    }
                } catch (error) {
                    setErrorCode((error as ApiErrorResponse).errorCode);
                    setShowError(error as Error);
                }
            } else {
                setErrorCode((error as ApiErrorResponse).errorCode);
                setShowError(error as Error);
            }
        }
        setBusy(false);
    };

    const isValid =
        (email.length === 0 || validateEmail(email)) && validatePhone(phone) && phone.length > 0 && sms && personal;

    return (
        <main className='main-content' id='main'>
            <h1 className='main-content__header'>{t('confirm.header')}</h1>
            <p className='main-content__subheader'>{t('confirm.subheader')}</p>
            {showError && (
                <ModalPortal
                    primaryActionLabel={t('error_ok_button.text')}
                    onPrimaryAction={() => {
                        setShowError(null);
                        setErrorCode('ILLEGAL_DBL');
                    }}
                >
                    <p>
                        <b>{t('confirm.error.header')}</b>
                        <br />
                        {t(`api.errors.${errorCode}`)}
                    </p>
                </ModalPortal>
            )}

            <div className='form-container'>
                <h4 className='form-container__header'>{t('confirm.form.header')}</h4>
                <div className='form-content'>
                    <Input
                        type='tel'
                        id='phone'
                        isValid={isPhoneValid}
                        required
                        label={t('confirm.form.telephone')}
                        value={phone}
                        onBlur={(val) => setIsPhoneValid(validatePhone(val))}
                        onChange={(e) => setPhone(e)}
                    />
                    {!isPhoneValid && <p className='input-error'>{t('confirm.form.phone_error')}</p>}
                    <Input
                        type='email'
                        id='email'
                        isValid={isEmailValid}
                        label={t('confirm.form.email')}
                        value={email}
                        onBlur={(val) => setIsEmailValid(validateEmail(val))}
                        onChange={(e) => setEmail(e)}
                    />
                    {!isEmailValid && <p className='input-error'>{t('confirm.form.email_error')}</p>}
                </div>
            </div>

            <div className='mobile-only'>
                <BookingInformation />
            </div>

            <div className='policy-container'>
                <h1 className='mobile-only policy-container__mobile-only-header'>
                    {t('confirm.policy.mobile_header')}
                </h1>
                <Checkbox onClick={(bool) => setSms(bool)} id='sms' label={t('confirm.policy.sms')} checked={sms} />
                <div>
                    <Checkbox
                        onClick={(bool) => setPersonal(bool)}
                        id='personal'
                        checked={personal}
                        ariaLabel={`${t('confirm.policy.personal_data')} ${t('confirm.policy.personal_data_policy')}`}
                        jsx={
                            <>
                                <span>{t('confirm.policy.personal_data')}</span>
                                &nbsp;
                                <Button variant='text' className='in-text-link' onClick={() => setShowModal(true)}>
                                    <span>{t('confirm.policy.personal_data_policy')}</span>
                                </Button>
                            </>
                        }
                    />
                </div>
                <Checkbox
                    onClick={(bool) => setMarketing(bool)}
                    id='marketing'
                    label={t('confirm.policy.storing_data')}
                    checked={marketing}
                />
            </div>

            <div className='confirm-next'>
                <Button id='booking-confirm' big disabled={!isValid} onClick={() => handleSubmit(bookingId as string)}>
                    <span>{t('confirm.button')}</span>
                </Button>
            </div>
            {showModal && (
                <ModalPortal onCloseClick={() => setShowModal(false)}>
                    <div className='policy-modal'>
                        <h1 className='policy-modal__header'>{t('confirm.policy.header')}</h1>
                        <p className='policy-modal__content'>{clinic?.termsText}</p>
                    </div>
                </ModalPortal>
            )}
        </main>
    );
}
