import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {BookingActions, useBookingContext} from 'src/context/BookingContext';
import {generateOpenUserKey, OpenUser} from 'src/lib/user/openUser';
import {Button} from 'src/components/form/button/Button';
import ModalPortal from 'src/components/layout/modal/ModalPortal';
import {PatientForm} from './PatientForm';
import './PeopleForm.scss';

export function PeopleForm() {
    const {t} = useTranslation();
    const {dispatch, bookingState} = useBookingContext();

    useEffect(() => window.scrollTo(0, 0), []);

    const [patients, setPatients] = useState<Array<OpenUser>>(() => {
        const users = Array.from(Array(bookingState.reasonState.numberPatients || 1)).map((_, index): OpenUser => {
            const user = index === 0 ? bookingState.user : (bookingState.user?.relations || [])[index - 1];

            if (user === undefined || user === null) {
                return {
                    uniqueKey: generateOpenUserKey(),
                    identity: '',
                    identityValid: false,
                    age: null,
                    givenName: '',
                    familyName: '',
                    gender: 'female'
                };
            }

            return {
                uniqueKey: generateOpenUserKey(),
                identity: user.identity,
                identityValid: true,
                age: user.age || null,
                givenName: user.givenName,
                familyName: user.familyName,
                gender: user.gender as 'male' | 'female'
            };
        });

        return users;
    });
    const [showNetworkError, setShowNetworkError] = useState(false);

    const peopleValid =
        patients
            .map((user) => {
                return user.familyName !== '' && user.givenName !== '' && user.identityValid;
            })
            .includes(false) === false;

    const identities = patients.length > 0 ? patients.map(({identity}) => identity) : [];
    const hasDuplicatePatients = peopleValid && new Set(identities).size !== identities.length;

    const {minAge, maxAge} = bookingState.reasonState.allowedAge || {};

    return (
        <>
            <main className='main-content people-form' id='main'>
                {showNetworkError && (
                    <ModalPortal
                        primaryActionLabel={t('error_ok_button.text')}
                        onPrimaryAction={() => setShowNetworkError(false)}
                    >
                        <p>
                            <b>{t('error_network_title.text')}</b>
                            <br />
                            {t('error_network_message.text')}
                        </p>
                    </ModalPortal>
                )}
                <h1 className='people__header'>{t('people_select.people_form.booking_header')}</h1>
                <p className='people__text wrap-html'>{t('people_select.people_form.booking_subheader')}</p>
                <p className='people__text wrap-html'>{t('people_select.people_list.consent_info')}</p>
                <p className='people__text wrap-html'>{t('people_select.people_form.booking_additional_subheader')}</p>
                {patients.map((patient, index) => {
                    return (
                        <div key={patient.uniqueKey} className='people-form__patient-form'>
                            <PatientForm
                                onNetworkError={() => setShowNetworkError(true)}
                                ageRange={[minAge || null, maxAge || null]}
                                identities={identities}
                                patient={patient}
                                index={index}
                                onChange={(updatedPatient) => {
                                    const newPatients = [...patients];
                                    newPatients[index] = updatedPatient;
                                    setPatients(newPatients);
                                }}
                            />
                        </div>
                    );
                })}
                <div className='people-form__footer'>
                    <Button
                        disabled={peopleValid === false || hasDuplicatePatients}
                        big
                        onClick={() => {
                            const [firstPatient, ...otherPatients] = patients;

                            const answerKeys = Object.keys(bookingState.healthDeclarationAnswers.answers);
                            answerKeys.forEach((key) => {
                                if (identities.includes(key) === false) {
                                    dispatch({type: BookingActions.REMOVE_HEALTH_DECLARATION, value: key});
                                }
                            });

                            dispatch({
                                type: BookingActions.SET_USER,
                                value: {
                                    identity: firstPatient.identity,
                                    givenName: firstPatient.givenName,
                                    familyName: firstPatient.familyName,
                                    gender: firstPatient.gender,
                                    relations: otherPatients.map((patient) => {
                                        return {
                                            relationType: 'unknown',
                                            identity: patient.identity,
                                            givenName: patient.givenName,
                                            familyName: patient.familyName,
                                            gender: patient.gender
                                        };
                                    })
                                }
                            });
                            dispatch({type: BookingActions.SET_SELECTED_IDS, value: identities});
                            dispatch({type: BookingActions.NEXT_STEP});
                        }}
                    >
                        <span>{t('button_next.text')}</span>
                    </Button>
                </div>
            </main>
        </>
    );
}
