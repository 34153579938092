import {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {UiNoValueActions, UiStringActions, useUiContext} from '../../../context/UiContext';
import {fetchCapacity} from '../../../services/api/api';
import {CapacityResponse, ClinicObjectSlim, ClinicObjectSlimWithCapacity} from '../../../services/api/types/types';
import {ClinicCapacity} from '../components/ClinicCapacity';
import './ClinicListItem.scss';

interface ClinicListItemProps {
    clinic: ClinicObjectSlimWithCapacity;
    reasonParams: Array<string>;
    distanceToClinic?: {value: number; unit: string};
}

export function ClinicListItem({clinic, reasonParams, distanceToClinic}: ClinicListItemProps) {
    const {state, dispatch} = useUiContext();
    const {t} = useTranslation();
    const listItemRef = useRef<HTMLDivElement>(null);

    const [intersecting, setIntersecting] = useState(false);
    const [timeData, setTimeData] = useState<null | CapacityResponse>(clinic?.capacity || null);
    const reasonParamsRef = useRef(reasonParams);

    const clinicDetailsUrl = process.env.REACT_APP_CLINIC_DETAILS_URL;
    const canHover = matchMedia('(hover: hover)').matches;
    const reasonQuery = reasonParams.length > 0 ? `?reason=${reasonParams.join('&reason=')}` : '';
    const clinicUrl = `${clinicDetailsUrl}/${clinic.id}${reasonQuery}`;

    const getBookingLabel = ({hasDropin, hasTimebook}: ClinicObjectSlim) => {
        if (hasDropin && hasTimebook) {
            return t('clinic_popup.booking_options.both');
        }
        if (hasDropin) {
            return t('clinic_popup.booking_options.dropin');
        }
        if (hasTimebook) {
            return t('clinic_popup.booking_options.timebook');
        }
    };

    useEffect(() => {
        if (reasonParams.length === 0) {
            setTimeData(null);
            return;
        }
        const abortSignal = new AbortController();

        const load = async () => {
            try {
                // Check if the reasonParams have changed since the last fetch and if we have a cached value
                if (timeData !== null && reasonParamsRef.current === reasonParams) {
                    return;
                }
                reasonParamsRef.current = reasonParams;

                const result = await fetchCapacity(parseInt(clinic.id, 10), abortSignal.signal, reasonParams);
                if (result === null) {
                    throw new Error('Failed to fetch capacity data');
                } else {
                    setTimeData(result);
                }
            } catch (error) {
                const err = error as Error;
                if (err.name === 'AbortError') {
                    // Probably aborted due to scrolling out of view, just ignore
                    return;
                }
                console.error(err);
                setTimeData(null);
            }
        };

        if (intersecting) {
            load();
        }

        return () => {
            abortSignal.abort();
        };
    }, [clinic.id, intersecting, reasonParams, timeData, clinic.capacity]);

    useEffect(() => {
        if (listItemRef.current === null) {
            return;
        }

        const observer = new IntersectionObserver(
            (entries) => {
                setIntersecting(entries[0].isIntersecting);
            },
            {
                rootMargin: '0px',
                threshold: 0.5
            }
        );

        observer.observe(listItemRef.current);

        return () => observer.disconnect();
    }, []);

    return (
        <div
            ref={listItemRef}
            role='button'
            className={`clinic-list-item${state.stickyClinicId === clinic.id ? ' clinic-list-item--sticky' : ''}`}
            onMouseEnter={() => {
                if (canHover) {
                    dispatch({type: UiStringActions.SET_HOVERED_CLINIC_ID, value: clinic.id});
                }
            }}
            onMouseLeave={() => {
                if (canHover) {
                    dispatch({type: UiStringActions.SET_HOVERED_CLINIC_ID, value: ''});
                }
            }}
            onClick={() => {
                if (canHover || state.smallDevice) {
                    return;
                }

                if (state.stickyClinicId === clinic.id) {
                    dispatch({type: UiNoValueActions.CLEAR_STICKY_CLINIC_ID});
                } else {
                    dispatch({type: UiStringActions.SET_STICKY_CLINIC_ID, value: clinic.id});
                }
            }}
            tabIndex={-1}
            onKeyDown={(e) => {
                if (e.key === 'Enter') {
                    if (state.stickyClinicId === clinic.id) {
                        dispatch({type: UiNoValueActions.CLEAR_STICKY_CLINIC_ID});
                    } else {
                        dispatch({type: UiStringActions.SET_STICKY_CLINIC_ID, value: clinic.id});
                    }
                }
            }}
        >
            {clinic.logoUrl && (
                <div className='clinic-list-item__logo'>
                    <img src={clinic.logoUrl} alt={clinic.name} aria-hidden={true} />
                </div>
            )}
            <p className='clinic-list-item__clinic-name'>
                <b>{clinic.name}</b>
            </p>
            <div className='clinic-list-item__content'>
                <div className='clinic-list-item__content__text-container'>
                    <p>
                        {distanceToClinic && (
                            <>
                                <span className='screen-reader-only'>
                                    {t('distance_to_clinic.arialabel', distanceToClinic)}
                                </span>
                                <span aria-hidden>{`${distanceToClinic.value} ${distanceToClinic.unit} •`}</span>
                            </>
                        )}
                        <span aria-hidden>{distanceToClinic && <>&nbsp;</>}</span>
                        {clinic.address && `${clinic.address}, ${clinic.city}`}
                    </p>
                    <p>{getBookingLabel(clinic)}</p>
                </div>

                {clinic.hasTimebook && reasonParams.length > 0 && timeData !== null && (
                    <ClinicCapacity capacity={timeData} visitReasons={reasonParams} />
                )}
            </div>

            <p className='clinic-list-item__link'>
                <a
                    aria-label={t('list_clinics_to_clinic.arialabel', {name: clinic.name})}
                    href={clinicUrl}
                    target='_blank'
                    className='btn btn--primary'
                    rel='noreferrer'
                >
                    {t('list_clinics_to_clinic.text')}
                </a>
            </p>
        </div>
    );
}
