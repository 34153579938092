import {useState, useRef, KeyboardEvent, useEffect, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {CSSTransition} from 'react-transition-group';
import {useNavigate, useLocation} from 'react-router-dom';
import {useUiContext} from '../../../context/UiContext';
import {Button} from '../../form/button/Button';
import {useSessionContext} from '../../../context/SessionContext';
import {useAppState} from '../../../hooks/useAppState';
import {MobileMenu} from './mobile-menu/MobileMenu';
import './Navbar.scss';

type MenuItems = {
    title: string;
    ref: React.RefObject<HTMLButtonElement>;
}[];

export function Navbar() {
    const {state} = useUiContext();
    const {breakPointInterval} = state;
    const {t} = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const {sessionState} = useSessionContext();
    const {signOut} = useAppState();

    const [mobileMenuActive, setMobileMenuActive] = useState(false);

    const skipNavRef = useRef<HTMLButtonElement | null>(null);
    const shouldShowNavigateToMainContent = useRef(false);
    const selectedMenuItem = useRef(0);

    const mapRef = useRef(null);
    const handleBookingRef = useRef(null);
    const vaccinationHistoryRef = useRef(null);
    const accountRef = useRef(null);

    const tabValues: MenuItems = useMemo(
        () => [
            {
                title: 'Map tab',
                ref: mapRef
            },
            {
                title: 'Hantera tab',
                ref: handleBookingRef
            },
            {
                title: 'Vaccinationshistorik tab',
                ref: vaccinationHistoryRef
            },
            {
                title: 'Konto tab',
                ref: accountRef
            }
        ],
        []
    );

    useEffect(() => {
        // Focus on the last focused element when the navbar is re-rendered after sign in
        if (selectedMenuItem.current !== 0) {
            tabValues[selectedMenuItem.current]?.ref?.current?.focus();
            shouldShowNavigateToMainContent.current = true;
        }
    }, [sessionState.hasActiveSession, tabValues]);

    // used for arrow navigation to keep track of the selected menu item
    const handleNextTab = (firstTabInRound: number, nextTab: number, lastTabInRound: number, shouldFocus?: boolean) => {
        const tabToSelect: number = selectedMenuItem.current === lastTabInRound ? firstTabInRound : nextTab;
        selectedMenuItem.current = tabToSelect;
        if (shouldFocus) {
            tabValues[tabToSelect]?.ref?.current?.focus();
        }
    };

    const handleEnterKeyPress = (event: KeyboardEvent) => {
        shouldShowNavigateToMainContent.current = true; // next tab should be skip navigation button
        switch ((event.target as HTMLElement)?.id) {
            case 'skip-navigation-button':
                shouldShowNavigateToMainContent.current = false; // next tab should be the main content
                break;
            case 'book-vaccination-button':
                selectedMenuItem.current = 0;
                navigate('/');
                break;
            case 'handle-booking-button':
                shouldShowNavigateToMainContent.current = false; // next tab should be the main content since booking is a modal
                selectedMenuItem.current = 0; // reset selected menu item
                navigate(`/hantera`, {
                    state: {
                        clinicId: 'map'
                    }
                });
                break;
            case 'vaccination-history-button':
                selectedMenuItem.current = 2;
                navigate(`/vaccinationshistorik`);
                document.getElementById('main-history-container')?.focus();
                break;
            case 'account-button':
                selectedMenuItem.current = 3;
                navigate(`/konto`);
                break;
            default:
                break;
        }
    };

    const handleEscapeKeyPress = (event: KeyboardEvent) => {
        tabValues[selectedMenuItem.current]?.ref?.current?.focus();
        event.preventDefault();
        event.stopPropagation();
    };

    const handleTabKeyPress = (event: KeyboardEvent, tabCount: number) => {
        if ((event.target as HTMLElement)?.id === 'skip-navigation-button') {
            shouldShowNavigateToMainContent.current = false;
        }
        // Handle shift + tab and tab key press to keep track of the selected menu item
        if (event.shiftKey) {
            selectedMenuItem.current = selectedMenuItem.current === 0 ? 0 : selectedMenuItem.current - 1;
        }
        // Handle tab key press to keep track of the selected menu item
        if (!event.shiftKey && (event.target as HTMLElement)?.id !== 'skip-navigation-button') {
            selectedMenuItem.current = selectedMenuItem.current === tabCount ? tabCount : selectedMenuItem.current + 1;
        }
        // show the skip navigation button as next element after enter is pressed
        if (shouldShowNavigateToMainContent.current) {
            skipNavRef?.current?.focus();
            event.preventDefault();
            event.stopPropagation();
            return;
        }
        // focus on previously focused element
        if ((event.target as HTMLElement)?.id === 'skip-navigation-button') {
            tabValues[selectedMenuItem.current]?.ref?.current?.focus();
            event.preventDefault();
            event.stopPropagation();
            return;
        }
    };

    const handleArrowKeyLeftPress = (tabCount: number) => {
        shouldShowNavigateToMainContent.current = false;
        const last = tabCount;
        const next = selectedMenuItem.current - 1;
        if (next < 0) {
            handleNextTab(last, tabCount, tabCount, true);
            return;
        }
        handleNextTab(last, next, 0, true);
    };

    const handleArrowKeyRightPress = (tabCount: number) => {
        shouldShowNavigateToMainContent.current = false;
        const first = 0;
        const next = selectedMenuItem.current === tabCount ? 0 : selectedMenuItem.current + 1;
        handleNextTab(first, next, tabCount, true);
    };

    const handleKeyPress = (event: KeyboardEvent) => {
        const tabCount = sessionState.hasActiveSession ? tabValues.length - 1 : tabValues.length - 2; // tab count is only for visible menu items
        switch (event.key) {
            case 'Enter': // Enter key to navigate to the selected menu item
                handleEnterKeyPress(event);
                break;
            case 'Escape': // Escape key to focus on previously focused element
                handleEscapeKeyPress(event);
                break;
            case 'Tab': // handle tab / shift tag key press
                handleTabKeyPress(event, tabCount);
                break;
            case 'ArrowLeft': // Arrow navigation for menu items excluding logo or logout button
                handleArrowKeyLeftPress(tabCount);
                break;
            case 'ArrowRight': // Arrow navigation for menu items excluding logo or logout button
                handleArrowKeyRightPress(tabCount);
                break;
            default:
                break;
        }
    };

    const openMobileMenu = (open: boolean) => {
        // Unsets Background Scrolling to use when mobile menu is closed to enable scrolling
        !open && (document.body.style.overflow = 'unset');
        // Disables Background Scrolling whilst the mobile menu is open, to prevent multiple scrollbars
        if (open && typeof window != 'undefined' && window.document) {
            document.body.style.overflow = 'hidden';
        }
        setMobileMenuActive(open);
    };
    const hideNavbar =
        ['/hantera', '/personuppgiftspolicy', '/cookie-policy'].includes(location?.pathname) ||
        location?.pathname?.includes('/mottagning');

    if (hideNavbar) {
        return null;
    }

    const getCurrentPageFirstFocusElement = () => {
        switch (location.pathname) {
            case '/':
                const firstFocusMapId = document.getElementById('search');
                return firstFocusMapId;
            case '/hantera':
                const firstFocusHandleBookingId = document.getElementById('main-handle-booking-container');
                return firstFocusHandleBookingId;
            case '/vaccinationshistorik':
                const bankIdFocusId = document.getElementById('bankid-button');
                const historyToSFocusId = document.getElementById('modal-portal-close-buttons');
                const firstNameFocusId = document.getElementById('history-container-first-focus');
                return bankIdFocusId || historyToSFocusId || firstNameFocusId;
            case '/konto':
                const historyBankIdFocusId = document.getElementById('bankid-button');
                const accountToSFocusId = document.getElementById('modal-portal-close-buttons');
                const firstFocusAccount = document.getElementById('edit-profile-button-id');
                return historyBankIdFocusId || accountToSFocusId || firstFocusAccount;
            default:
                const firstFocusMapIdDefault = document.getElementById('search');
                return firstFocusMapIdDefault;
        }
    };

    const focusOnFirstChild = () => {
        const firstChildElement: HTMLElement | null = getCurrentPageFirstFocusElement();
        firstChildElement && firstChildElement?.focus();
    };

    return (
        <>
            <div className='skip-nav-container'>
                <button
                    id='skip-navigation-button'
                    onKeyDown={handleKeyPress}
                    ref={skipNavRef}
                    tabIndex={-1}
                    className='skip-nav-container__nav-link'
                    onClick={focusOnFirstChild}
                >
                    {t('clinic_header.skip_navigation_text')}
                </button>
            </div>
            <nav className='navbar'>
                <button onClick={() => navigate('/')}>
                    <img className='navbar__logo' src='/mittvaccin_logo.svg' alt={t('logo_alt.text')} />
                </button>

                {['compact', 'medium'].includes(breakPointInterval) ? (
                    <Button
                        thin
                        variant='lighter'
                        className='navbar__mobile-menu-button'
                        onClick={() => openMobileMenu(true)}
                    >
                        <span>{t('general.menu')}</span>
                    </Button>
                ) : (
                    <div className='navbar__nav-buttons'>
                        <button
                            id='book-vaccination-button'
                            onClick={() => {
                                navigate('/');
                            }}
                            onKeyDown={handleKeyPress}
                            role='menuitem'
                            aria-owns='main-search-heading'
                            ref={tabValues[0].ref}
                        >
                            <span
                                className={`navbar__nav-buttons__nav-button${
                                    ['/', '/hitta'].includes(location.pathname) ? '--selected' : ''
                                }`}
                            >
                                {t('handle_booking.book_vaccinations')}
                            </span>
                        </button>
                        <button
                            id='handle-booking-button'
                            onClick={() => {
                                navigate(`/hantera`, {
                                    state: {
                                        clinicId: 'map'
                                    }
                                });
                            }}
                            onKeyDown={handleKeyPress}
                            role='menuitem'
                            aria-owns='handle-booking-identity'
                            ref={tabValues[1].ref}
                        >
                            <span
                                className={`navbar__nav-buttons__nav-button${
                                    location.pathname === '/hantera' ? '--selected' : ''
                                }`}
                            >
                                {t('handle_booking.handle_booking_heading')}
                            </span>
                        </button>
                        <button
                            id='vaccination-history-button'
                            onClick={() => {
                                navigate(`/vaccinationshistorik`);
                            }}
                            role='menuitem'
                            onKeyDown={handleKeyPress}
                            ref={tabValues[2].ref}
                            aria-owns='history-sign-in toc-modal age-restriction-modal vaccination-history-content'
                        >
                            <span
                                className={`navbar__nav-buttons__nav-button${
                                    location.pathname === '/vaccinationshistorik' ? '--selected' : ''
                                }`}
                            >
                                {t('history.heading')}
                            </span>
                        </button>
                        {sessionState.hasActiveSession && (
                            <button
                                id='account-button'
                                onClick={() => {
                                    navigate(`/konto`);
                                }}
                                role='menuitem'
                                onKeyDown={handleKeyPress}
                                ref={tabValues[3].ref}
                                aria-owns='account-main-profile-container'
                            >
                                <span
                                    data-testid='account-button'
                                    className={`navbar__nav-buttons__nav-button${
                                        location.pathname === '/konto' ? '--selected' : ''
                                    }`}
                                >
                                    {t('account.header')}
                                </span>
                            </button>
                        )}

                        {sessionState.hasActiveSession && (
                            <Button
                                data-testid='account-button'
                                className='navbar__nav-buttons__auth-button'
                                variant='lighter'
                                ariaLabel={t('general.signOut')}
                                onClick={() => signOut()}
                            >
                                {t('general.signOut')}
                            </Button>
                        )}
                    </div>
                )}
            </nav>
            <div>
                <CSSTransition
                    unmountOnExit
                    timeout={{enter: 0, exit: 200}}
                    in={['compact', 'medium'].includes(breakPointInterval) && mobileMenuActive}
                    onExit={mobileMenuActive ? () => openMobileMenu(false) : undefined}
                    classNames={'map-mobile-menu'}
                >
                    <MobileMenu onCloseClick={() => openMobileMenu(false)} />
                </CSSTransition>
            </div>
        </>
    );
}
