import {useEffect} from 'react';
import {PlusIcon} from '../../../icons/PlusIcon';
import {MinusIcon} from '../../../icons/MinusIcon';
import {Button} from '../../../form/button/Button';
import {useClinicDetailsContext} from '../../../../context/ClinicDetailsContext';
import {useTranslation} from 'react-i18next';
import {useSearchParams} from 'react-router-dom';
import {AppointmentType, ReasonFilterObject} from '../../../../services/api/types/types';
import {BookingActions, useBookingContext} from '../../../../context/BookingContext';
import {VisitCategory} from '../../../main/components/PickVisitCategory';
import {getHealthDeclarationId, getMinMaxAge, getUrlParamReason, minMaxPeopleForType} from './clinicFormUtils';

import './style.scss';

export function ClinicForm() {
    const {t} = useTranslation();

    const {dispatch, bookingState} = useBookingContext();
    const {dataState} = useClinicDetailsContext();
    const {visitReasons, appointmentCategories, clinic} = dataState;
    const {bookingType, reasonState} = bookingState;

    const {reason, appointmentType, numberPatients} = reasonState;

    const [urlSearchParams] = useSearchParams();

    const selectedReasonId = reason?.id ? parseInt(reason.id) : -1;
    const selectedAppointmentTypeId = appointmentType?.id ? appointmentType.id : -1;

    const appointmentTypesFlat = appointmentCategories
        .flatMap(({appointmentTypes}) => appointmentTypes)
        .filter(({visitReasonIds}) => visitReasonIds.includes(selectedReasonId));
    const [minPeople, maxPeople] = minMaxPeopleForType(appointmentTypesFlat, selectedReasonId);
    const numberOfPeople = numberPatients || minPeople;

    const appointmentCategoryGroups = appointmentCategories
        .filter(({appointmentTypes}) => {
            return appointmentTypes.some(({visitReasonIds}) => visitReasonIds.includes(selectedReasonId));
        })
        .map((cat) => {
            return {
                ...cat,
                appointmentTypes: cat.appointmentTypes.filter(({patients, eidSelfOnly, visitReasonIds}) => {
                    // Safety belt, remove any self only bookable types with >1 patients
                    if (eidSelfOnly && numberOfPeople > 1) {
                        return false;
                    }

                    return visitReasonIds.includes(selectedReasonId) && patients === numberOfPeople;
                })
            };
        });

    const clinicAvailableReasons = visitReasons
        .filter((reason) => {
            return clinic?.availableVisitReasons.includes(parseInt(reason.id, 10));
        })
        .sort((a, b) => b.category.localeCompare(a.category));

    const clinicReasonsGroups = clinicAvailableReasons.reduce((acc, reason) => {
        const {category} = reason;
        acc[category] = acc[category] ? acc[category] : [];

        acc[category].push(reason);

        return acc;
    }, {} as Record<VisitCategory, Array<ReasonFilterObject>>);

    const nextDisabled = bookingType === 'dropIn' ? false : selectedReasonId === -1 || selectedAppointmentTypeId === -1;

    // Initial BookingContext state handling with reason parameter
    useEffect(() => {
        const paramReason = getUrlParamReason(urlSearchParams.getAll('reason'), clinic?.availableVisitReasons || []);
        if (!paramReason || reason !== null) {
            return;
        }

        const appointmentTypesFlat = appointmentCategories
            .flatMap(({appointmentTypes}) =>
                appointmentTypes.filter(({patients, eidSelfOnly}) => {
                    if (eidSelfOnly) {
                        return patients === 1;
                    }
                    return true;
                })
            )
            .filter(({visitReasonIds}) => visitReasonIds.includes(paramReason));

        const [minPeople] = minMaxPeopleForType(appointmentTypesFlat, paramReason);
        const initialReason = visitReasons.find(({id}) => parseInt(id, 10) === paramReason) as ReasonFilterObject;
        const initialTypes = appointmentTypesFlat.filter(({patients}) => patients === minPeople);
        const visitType = initialTypes.length === 1 ? initialTypes[0].id : -1;

        let matchingTypes = appointmentTypesFlat.filter(({id}) => id === visitType);
        if (!matchingTypes.length) {
            matchingTypes = appointmentTypesFlat.filter(({patients}) => patients === minPeople);
        }

        const appointmentType = matchingTypes.length === 1 ? matchingTypes[0] : null;

        dispatch({
            type: BookingActions.SET_REASON_DATA,
            value: {
                reason: initialReason,
                numberPatients: minPeople,
                appointmentType,
                healthDeclarationDefinitionId: getHealthDeclarationId(appointmentCategories, appointmentType?.id ?? 0),
                allowedAge: getMinMaxAge(appointmentCategories, appointmentType?.id ?? 0)
            }
        });
    }, [appointmentCategories, clinic?.availableVisitReasons, dispatch, reason, urlSearchParams, visitReasons]);

    const onReasonChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const clickedReason = parseInt(e.target.value, 10);
        const matchingTypesFlat = appointmentCategories
            .flatMap(({appointmentTypes}) =>
                appointmentTypes.filter(({patients, eidSelfOnly}) => {
                    if (eidSelfOnly) {
                        return patients === 1;
                    }
                    return true;
                })
            )
            .filter(({visitReasonIds}) => visitReasonIds.includes(clickedReason || -1));

        const [minPeople] = minMaxPeopleForType(matchingTypesFlat);

        let matchingTypes = matchingTypesFlat.filter(({id}) => id === selectedAppointmentTypeId);
        if (!matchingTypes.length) {
            matchingTypes = matchingTypesFlat.filter(({patients}) => patients === numberPatients);
        }
        if (!matchingTypes.length) {
            matchingTypes = matchingTypesFlat.filter(({patients}) => patients === minPeople);
        }

        const reason = visitReasons.find(({id}) => parseInt(id, 10) === clickedReason) as ReasonFilterObject;
        if (matchingTypes.length === 1) {
            const matchingType = matchingTypes[0];
            dispatch({
                type: BookingActions.SET_REASON_DATA,
                value: {
                    reason: reason,
                    numberPatients: matchingType.patients,
                    appointmentType: matchingType,
                    healthDeclarationDefinitionId: getHealthDeclarationId(appointmentCategories, matchingType.id),
                    allowedAge: getMinMaxAge(appointmentCategories, matchingType.id)
                }
            });
        } else {
            const minOrSameNumPeople =
                matchingTypesFlat.find(({patients}) => patients === numberOfPeople)?.patients || minPeople;
            dispatch({
                type: BookingActions.SET_REASON_DATA,
                value: {
                    reason: reason,
                    numberPatients: minOrSameNumPeople,
                    appointmentType: null,
                    healthDeclarationDefinitionId: null,
                    allowedAge: null
                }
            });
        }
    };

    const onNumberPeopleClick = (amount: number) => {
        const newNumber = numberOfPeople + amount;

        const matchingTypes = appointmentCategories
            .flatMap(({appointmentTypes}) =>
                appointmentTypes.filter(({patients, eidSelfOnly}) => {
                    if (eidSelfOnly) {
                        return patients === 1;
                    }
                    return true;
                })
            )
            .filter(
                ({visitReasonIds, patients}) =>
                    visitReasonIds.includes(selectedReasonId || -1) && patients === newNumber
            );

        if (matchingTypes.length === 1) {
            const matchingType = matchingTypes[0];
            dispatch({
                type: BookingActions.SET_REASON_DATA,
                value: {
                    ...reasonState,
                    numberPatients: newNumber,
                    appointmentType: matchingType,
                    healthDeclarationDefinitionId: getHealthDeclarationId(appointmentCategories, matchingType.id)
                }
            });
        } else {
            dispatch({
                type: BookingActions.SET_REASON_DATA,
                value: {
                    ...reasonState,
                    numberPatients: newNumber,
                    appointmentType: null,
                    healthDeclarationDefinitionId: null
                }
            });
        }
    };

    const selectType = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const matchingType = appointmentTypesFlat.find(
            ({id}) => id === parseInt(e.target.value, 10)
        ) as AppointmentType;

        dispatch({
            type: BookingActions.SET_REASON_DATA,
            value: {
                ...reasonState,
                appointmentType: matchingType,
                healthDeclarationDefinitionId: getHealthDeclarationId(appointmentCategories, matchingType.id),
                allowedAge: getMinMaxAge(appointmentCategories, matchingType.id)
            }
        });
    };

    const isNumPeopleDisabled = (maxNumber: number) => {
        return selectedReasonId === -1 || numberOfPeople === maxNumber;
    };

    return (
        <form
            className='clinic-form'
            aria-label={
                bookingType === 'booking' ? t('clinic_form.form_aria_label') : t('clinic_form.dropin_form_aria_label')
            }
        >
            {bookingType === 'dropIn' && (
                <>
                    <p className='dropin-info'>{t('clinic_form.drop_in_info')}</p>
                    {clinic?.eidDropinSelfOnly && (
                        <p className='dropin-info'>
                            <b>{t('clinic_form.drop_in_self_only_info')}</b>
                        </p>
                    )}
                </>
            )}
            {bookingType === 'booking' && (
                <>
                    <div aria-live='polite' aria-atomic='true' className='screen-reader-only'>
                        {selectedReasonId === -1 && <p>{t('clinic_form.screen_reader_fill_in_form')}</p>}
                        {selectedReasonId !== -1 && (
                            <p>
                                {t('clinic_form.screen_reader_reason', {visitReason: reason?.label})}
                                {t('clinic_form.screen_reader_people_count', {numberOfPeople})}
                                {appointmentType !== null && (
                                    <>{t('clinic_form.screen_reader_visit_type', {visitType: appointmentType.name})}</>
                                )}
                            </p>
                        )}
                    </div>
                    <span className='select-container'>
                        {selectedReasonId !== -1 && (
                            <label htmlFor='reason' className='select-with-label'>
                                {t('clinic_form.type_of_vaccine')}
                            </label>
                        )}
                        <select
                            id='reason'
                            value={selectedReasonId}
                            className={`select ${selectedReasonId !== -1 ? 'has-selection' : ''}`}
                            onChange={(e) => onReasonChange(e)}
                        >
                            <option disabled value={-1}>
                                {t('clinic_form.type_of_vaccine')}
                            </option>
                            {Object.keys(clinicReasonsGroups).map((key) => {
                                return (
                                    <optgroup key={key} label={t(`reason_group.${key as VisitCategory}`)}>
                                        {clinicReasonsGroups[key as VisitCategory].map((reason) => {
                                            return (
                                                <option key={reason.id} value={reason.id}>
                                                    {reason.id === `${selectedReasonId}`
                                                        ? `${t(`reason_group.${reason.category}`)}  - `
                                                        : ''}{' '}
                                                    {reason.label}
                                                </option>
                                            );
                                        })}
                                    </optgroup>
                                );
                            })}
                        </select>
                    </span>
                    <div className='amount-input'>
                        <label className='amount-input__label'>{t('clinic_form.people_count_vaccine')}</label>
                        <div className='amount-input__label__content'>
                            <button
                                disabled={isNumPeopleDisabled(minPeople)}
                                className='amount-button'
                                type='button'
                                onClick={() => onNumberPeopleClick(-1)}
                                aria-label='minus'
                            >
                                <MinusIcon />
                            </button>
                            <span className='amount-input__label__content__input'>{numberOfPeople}</span>
                            <button
                                disabled={isNumPeopleDisabled(maxPeople)}
                                className='amount-button'
                                type='button'
                                onClick={() => onNumberPeopleClick(1)}
                                aria-label='plus'
                            >
                                <PlusIcon />
                            </button>
                        </div>
                    </div>
                    {numberOfPeople > 1 && <p className='amount-input__info'>{t('clinic_form.many_people_info')}</p>}
                    <span className='select-container'>
                        {selectedAppointmentTypeId !== -1 && (
                            <label htmlFor='visit-type' className='select-with-label'>
                                {t('clinic_form.type_of_visit')}
                            </label>
                        )}
                        <select
                            disabled={selectedReasonId === -1}
                            id='visit-type'
                            value={selectedAppointmentTypeId}
                            className={`select${selectedAppointmentTypeId !== -1 ? ' has-selection' : ''}`}
                            onChange={(e) => selectType(e)}
                        >
                            <option disabled value={-1}>
                                {t('clinic_form.type_of_visit')}
                            </option>
                            {appointmentCategoryGroups.map((cat) => {
                                if (cat.appointmentTypes.length === 0) {
                                    const maxPatients = Math.max(
                                        ...(appointmentCategories
                                            .find(({id}) => id === cat.id)
                                            ?.appointmentTypes.map(({patients}) => patients) || [])
                                    );
                                    return (
                                        <optgroup key={cat.id} label={cat.name}>
                                            <option disabled key={`${cat.id}-0`} value='-1'>
                                                {t('clinic_form.max_people_needed', {count: maxPatients})}
                                            </option>
                                        </optgroup>
                                    );
                                }

                                return (
                                    <optgroup key={cat.id} label={cat.name}>
                                        {cat.appointmentTypes.map((type) => {
                                            return (
                                                <option key={`${cat.id}-${type.id}`} value={type.id}>
                                                    {type.name}
                                                </option>
                                            );
                                        })}
                                    </optgroup>
                                );
                            })}
                        </select>
                    </span>
                    {appointmentType?.eidSelfOnly === true && (
                        <p className='select-container__info'>{t('clinic_form.self_only_info')}</p>
                    )}
                </>
            )}
            <Button
                id='booking-start'
                onClick={() => {
                    if (bookingType === 'dropIn') {
                        // TODO: Probably add specific Action for clearing reason data
                        dispatch({
                            type: BookingActions.SET_REASON_DATA,
                            value: {
                                reason: null,
                                numberPatients: null,
                                appointmentType: null,
                                healthDeclarationDefinitionId: null,
                                allowedAge: null
                            }
                        });
                    }
                    dispatch({type: BookingActions.NEXT_STEP});
                }}
                disabled={nextDisabled}
            >
                {t('clinic_form.next')}
            </Button>
        </form>
    );
}
